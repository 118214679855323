import React, { useContext } from "react";

import { DataContext } from "../../../../../providers/GasCheckoutDataProvider";
import {
    formattedToNumber,
    roundNumber,
} from "../../../../../utils/NumberHelpers";
import OrderBottomBar from "../../../../shared/OrderBottomBar";

const BottomBar = () => {
    const dataContext = useContext(DataContext);
    const { productChoice, result } = dataContext.data;

    if (result && result.products) {
        return (
            <OrderBottomBar
                leftText="Monatlich:"
                price={`${roundNumber(
                    formattedToNumber(
                        result.products[productChoice].gesamtpreisOhneBoniBrutto
                    ) - 100
                )} €`}
                rightText="Preis im Jahr:"
                totalPrice={`${result.products[productChoice].gesamtpreisOhneBoniBrutto} €`}
            />
        );
    }
};

export default BottomBar;
