import React, { useContext } from "react";

import styled from "@emotion/styled";

import { DataContext } from "../../../../../providers/GasCheckoutDataProvider";
import { navigate } from "../../../../shared/LinkComponent";
import Button from "../../../../shared/NewForms/Button";
import Section from "../../../../shared/Section";
import TextAlign from "../../../../shared/TextAlign";

const FinalWrapper = styled.div`
    text-align: center;
`;

const StepButtons = () => {
    const dataContext = useContext(DataContext);
    const { editMode, currentStepIndex, checkoutData } = dataContext.data;
    const { previousStepVisible } = dataContext.actions;
    const { nextStep, previousStep, setEditMode, setStepButtonAction } =
        dataContext.actions;

    const next = () => {
        setStepButtonAction("next");
        if (editMode) {
            setEditMode(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate("/gas/bestellung/uebersicht");
        } else {
            nextStep();
        }
    };

    const previous = () => {
        setStepButtonAction("previous");
        if (editMode) {
            setEditMode(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            navigate("/gas/bestellung/uebersicht");
        } else {
            previousStep();
        }
    };

    return (
        <Section withMargin>
            {currentStepIndex < 3 ? (
                <div className="columns is-mobile ">
                    <div className="column">
                        {previousStepVisible() && (
                            <Button
                                color="grey"
                                onClick={previous}
                                data-gtag="gas:checkout:previous"
                            >
                                Zurück
                            </Button>
                        )}
                    </div>
                    <TextAlign align="right" className="column">
                        {checkoutData && currentStepIndex != 4 && (
                            <Button
                                onClick={next}
                                data-gtag="gas:checkout:next"
                            >
                                {editMode ? "Speichern" : "Weiter"}
                            </Button>
                        )}
                    </TextAlign>
                </div>
            ) : (
                <FinalWrapper>
                    {checkoutData && currentStepIndex != 4 && (
                        <Button onClick={next}>
                            Kostenpflichtig bestellen
                        </Button>
                    )}
                </FinalWrapper>
            )}
        </Section>
    );
};

export default StepButtons;
