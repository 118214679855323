const campaigns = {
    mitmachen2020: {
        validFrom: "2020-11-09 12:19:00",
        validTil: "2020-12-31 23:59:59",
    },
    adventCalendar2020: {
        validFrom: "2020-11-27 00:00:00",
        validTil: "2020-12-31 23:59:59",
    },
    easterWishes: {
        validFrom: "2021-04-04 00:00:00",
        validTil: "2021-04-05 23:59:59",
    },
    monthlyOfferApril: {
        validFrom: "2021-04-01 00:00:00",
        validTil: "2021-04-30 23:59:59",
    },
    pelletsSummerCampaigns: {
        validFrom: "2021-05-24 00:00:00",
        validTil: "2021-08-27 23:59:59",
    },
    adBlueOfferJune: {
        validFrom: "2021-06-01 00:00:00",
        validTil: "2021-06-30 23:59:59",
    },
    hoyerPay: {
        validFrom: "2021-06-30 00:00:00",
        validTil: "2099-06-30 23:59:59",
    },
    HEL2021: {
        validFrom: "2021-10-01 08:00:00",
        validTil: "2021-10-31 23:59:59",
    },
    tankTechnikKnallerpreise: {
        validFrom: "2021-11-15 08:00:00",
        validTil: "2022-01-21 23:59:59",
    },
    gutscheinCard: {
        validFrom: "2021-12-01 00:00:00",
        validTil: "2022-12-31 23:59:59",
    },
    bannerPreChristmas2021: {
        validFrom: "2021-12-15 00:00:00",
        validTil: "2021-12-23 23:59:59",
    },
    bannerChristmas2021: {
        validFrom: "2021-12-24 00:00:00",
        validTil: "2021-12-26 23:59:59",
    },
    bannerAfterChristmas2021: {
        validFrom: "2021-12-27 00:00:00",
        validTil: "2021-12-31 23:59:59",
    },
    bannerNewYear2021: {
        validFrom: "2022-01-01 00:00:00",
        validTil: "2022-01-03 23:59:59",
    },
    winterHeader: {
        validFrom: "2021-12-15 00:00:00",
        validTil: "2022-02-28 23:59:59",
    },
};

export default campaigns;
