import React, { useContext, useEffect } from "react";

import { css, useTheme } from "@emotion/react";

import GasCheckoutDataProvider, {
    DataContext,
} from "../../../../providers/GasCheckoutDataProvider";
import checkoutSteps from "../../../../providers/GasCheckoutSteps";
import Campaign from "../../../shared/Campaign";
import GasElectricityCrossselling from "../../../shared/Campaign/components/GasElectricityCrossselling";
import Container from "../../../shared/Container";
import OrderStepper from "../../../shared/OrderStepper";
import BottomBar from "./BottomBar";
import StepButtons from "./StepButtons";

const CheckoutPage = () => {
    const theme = useTheme();
    const dataContext = useContext(DataContext);
    const {
        steps,
        currentStepIndex,
        orderData,
        street,
        houseNum,
        place,
        zipcode,
    } = dataContext.data;
    const prices = dataContext.actions.getPrices();

    const currentStepComponent = checkoutSteps[currentStepIndex].component;
    const currentStep = checkoutSteps[currentStepIndex].step;
    const crossSelling = checkoutSteps[currentStepIndex].crossSelling
        ? true
        : false;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentStepIndex]);

    const newStyles = css`
        font-family: var(--font-family-vito);
        color: ${theme.colors.blue.toString()};
    `;

    return (
        <React.Fragment>
            <Container slim css={newStyles}>
                {currentStep !== null && (
                    <OrderStepper steps={steps} currentStep={currentStep} />
                )}

                {currentStepComponent}
                {currentStepIndex < checkoutSteps.length && <StepButtons />}
                {crossSelling && (
                    <Campaign name="mitmachen2020">
                        <Campaign.Content>
                            {orderData !== null && (
                                <GasElectricityCrossselling
                                    to="/strom"
                                    zipcode={zipcode}
                                    city={place.value}
                                    street={street.value}
                                    houseNumber={houseNum}
                                    lsPrefix="ele"
                                />
                            )}
                        </Campaign.Content>
                    </Campaign>
                )}
            </Container>
            {prices && <BottomBar />}
        </React.Fragment>
    );
};

const WithProvider = ({ checkoutStep }) => {
    return (
        <GasCheckoutDataProvider {...{ checkoutStep }}>
            <CheckoutPage />
        </GasCheckoutDataProvider>
    );
};

export default WithProvider;
