import React, { memo } from "react";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrAfter";
import isSameOrAfter from "dayjs/plugin/isSameOrBefore";

import campaigns from "./campaigns";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const Campaign = ({ children, name }) => {
    const isActive = campaignIsActive(name);

    const renderWithProps = (child, index = 0) =>
        React.cloneElement(child, {
            isActive: isActive,
            key: index,
        });

    // Render the children. Provide them with the information if the campaign is active or not.
    if (children) {
        if (Array.isArray(children)) {
            return children.map((child, index) => {
                return renderWithProps(child, index);
            });
        }
        return renderWithProps(children);
    }

    return null;
};

/**
 * The content which should be rendered if the campaign is active.
 */
Campaign.Content = memo(({ children, isActive }) => {
    if (isActive) {
        return children;
    }
    return null;
});
Campaign.Content.displayName = "Campaign.Content";

/**
 * The fallback content which should be rendered if the campaing is not active.
 */
Campaign.Fallback = memo(({ children, isActive }) => {
    if (!children || isActive) {
        return null;
    }
    return children;
});
Campaign.Fallback.displayName = "Campaign.Fallback";

export default Campaign;

/**
 * Check if the campaign is active
 * @param {*} name
 */
export const campaignIsActive = (name) => {
    let campaignIsActive = false;
    // Check if the campaign is active
    if (campaigns[name]) {
        campaignIsActive =
            dayjs().isSameOrAfter(dayjs(campaigns[name].validFrom)) &&
            dayjs().isSameOrBefore(dayjs(campaigns[name].validTil));
    }

    return campaignIsActive;
};
