import React from "react";

import { graphql } from "gatsby";

import CheckoutPage from "../../../components/pages/Gas/CheckoutPage";
import Robots from "../../../components/shared/Meta/Robots";
import Page, {
    PRODUCT_GAS,
    RESPONSIBLE_COMPANY_HOYER_STROM_GAS,
} from "../../../components/shared/Page";
import GasDataProvider from "../../../providers/GasDataProvider";
import Error404Page from "../../404";

const GasBestellungPage = ({ params: { checkoutStep } }) =>
    checkoutStep == null ? (
        <Error404Page />
    ) : (
        <Page
            responsibleCompany={RESPONSIBLE_COMPANY_HOYER_STROM_GAS}
            product={PRODUCT_GAS}
        >
            <Robots index={false} />

            <GasDataProvider>
                <CheckoutPage {...{ checkoutStep }} />
            </GasDataProvider>
        </Page>
    );
export default GasBestellungPage;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "gas" }) {
            ...Page
        }
    }
`;
